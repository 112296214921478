import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticClass:"px-sm text-left shadow-sm",attrs:{"color":_vm.$vuetify.theme.dark ? 'dark' : _vm.getThemeMode.appBarColor,"dark":_vm.getThemeMode.appBarColor != 'white' ? true : false,"app":"","height":"75"}},[_c(VAppBarNavIcon,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple",value:({class: 'primary--text'}),expression:"{class: 'primary--text'}"}],on:{"click":_vm.toggleCompactVerticalSidebarDrawer}}),_c(VProgressLinear,{attrs:{"active":_vm.getThemeMode.isLoading,"indeterminate":_vm.getThemeMode.isLoading,"absolute":"","bottom":"","color":"primary"}}),_c(VToolbarTitle,[_vm._v("Octavia")]),_c(VSpacer),_c(VBadge,{attrs:{"bordered":"","overlap":"","content":"3","color":"red","offset-x":"22","offset-y":"22"}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-bell")])],1)],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-magnify")])],1),_c(VChip,{staticClass:"transparent py-5",attrs:{"pill":""}},[_vm._v(" Hi, Watson "),_c(VAvatar,{staticClass:"ml-2"},[_c(VImg,{attrs:{"src":require("@/assets/images/faces/1.jpg")}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }